import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Font-size : quelles solutions ?",
  "description": "Comment gérer les tailles de police de votre site au mieux.",
  "date": "2013-06-20T00:00:00.000Z",
  "path": "/articles/font-size-quelles-solutions/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Le choix est large quand on s’intéresse à la propriété CSS `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-size`}</code>{` : on peut tout aussi bien lui attribuer des valeurs prédéfinies (`}<code parentName="p" {...{
        "className": "text"
      }}>{`small`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`medium`}</code>{` etc.) que des valeurs absolues (`}<code parentName="p" {...{
        "className": "text"
      }}>{`pt`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`cm`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`mm`}</code>{` etc.) ou encore des valeurs relatives (`}<code parentName="p" {...{
        "className": "text"
      }}>{`px`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`%`}</code>{`, etc.). Le but de mon article est de faire un point sur les unités `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`rem`}</code>{` et leurs usages.`}</p>
    <h2>{`Em et Rem : un rappel`}</h2>
    <p>{`Ces unités sont parfois un peu dures à appréhender car leur définition reste plutôt abstraite. Pour reprendre celle du site de `}<a parentName="p" {...{
        "href": "http://www.w3.org/Style/Examples/007/units"
      }}>{`w3.org`}</a>{` :`}</p>
    <blockquote>
      <p parentName="blockquote">{`L’unité `}<code parentName="p" {...{
          "className": "text"
        }}>{`em`}</code>{` dépend de la police de caractères et peut être différente pour chaque élément du document `}{`[…]`}{` Exprimer des tailles, comme les margins et les paddings, en `}<code parentName="p" {...{
          "className": "text"
        }}>{`em`}</code>{` signifie qu’elles sont relatives à la taille de la police, et si l’utilisateur a une large police de caractères (sur un grand écran par exemple) ou au contraire une petite police (sur un smartphone par exemple), les tailles seront en proportion.`}</p>
    </blockquote>
    <p>{`De mon point de vue, il est préférable d’utiliser des `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{` afin de permettre que le texte soit redimensionnable afin de mieux s’adapter aux différentes résolutions (pour info les navigateurs modernes redimensionnent automatiquement les dimensions en `}<code parentName="p" {...{
        "className": "text"
      }}>{`px`}</code>{`).`}</p>
    <h2>{`62.5% : le nombre magique ?`}</h2>
    <p>{`Afin de simplifier les calculs de taille de police en utilisant les `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{`, une solution souvent utilisée est de définir le code suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`body {font-size: 62.5%}`}</code></pre></div>
    <p>{`Sachant que la taille de police par défaut des navigateurs modernes est (`}<a parentName="p" {...{
        "href": "http://isitrwd.com/rfs/"
      }}>{`presque ?`}</a>{`) tout le temps `}<code parentName="p" {...{
        "className": "text"
      }}>{`16px`}</code>{` pour `}<code parentName="p" {...{
        "className": "text"
      }}>{`1em`}</code>{`, la conversion est donc beaucoup plus simple suite à ce calcul et donnera le résultat suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`10 px = 1 em`}</code></pre></div>
    <p>{`L’inconvénient de cette méthode, c’est que suite à ce changement la taille par défaut du texte sera de `}<code parentName="p" {...{
        "className": "text"
      }}>{`10px`}</code>{` à part si on ne le précise pas ailleurs. Utilisé en production, je trouve ça plutôt pénible et potentiellement source d’erreurs.`}</p>
    <p>{`Du coup je ne touche du coup plus à la `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-size`}</code>{` de mon body et je la laisse par défaut. Je l’avais déjà lu chez `}<a parentName="p" {...{
        "href": "http://filamentgroup.com/lab/how_we_learned_to_leave_body_font_size_alone/"
      }}>{`Filament Group`}</a>{`, mais je fais ça depuis un petit moment et je trouve ça plus simple à gérer.`}</p>
    <h2>{`Rem : Sass to the rescue !`}</h2>
    <p><code parentName="p" {...{
        "className": "text"
      }}>{`Em`}</code>{` est donc une unité intéressante à utiliser, à un détail près : sa base variera selon la taille de la police, pouvant engendrer des différences de mise en page parfois pénibles (par exemple sur les margins et paddings d’un texte d’une certaine taille).`}</p>
    <p>{`Cet inconvénient est toutefois corrigé par l’unité `}<code parentName="p" {...{
        "className": "text"
      }}>{`rem`}</code>{` (pour `}<em parentName="p">{`root em`}</em>{`). Celle-ci fonctionne sur le même principe que les `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{`, à la différence près qu’elle utilise comme base de départ la racine html. Elle est donc moins source d’erreurs de calcul. Par contre elle n’est pas supportée par IE8- (mais un `}<a parentName="p" {...{
        "href": "https://github.com/chuckcarpenter/REM-unit-polyfill"
      }}>{`polyfill`}</a>{` existe).`}</p>
    <p>{`Si on ne souhaite pas utiliser de polyfill, on se retrouve donc face à un casse-tête à chaque fois qu’on utilise des `}<code parentName="p" {...{
        "className": "text"
      }}>{`rem`}</code>{`, car il faudra également prévoir et calculer un fallback en `}<code parentName="p" {...{
        "className": "text"
      }}>{`px`}</code>{` pour les navigateurs ne reconnaissant pas cette unité…`}</p>
    <p>{`Et c’est précisément là que Sass intervient : il va faire le calcul à notre place grâce à un `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixin`}</code>{`. Celui que j’utilise est le suivant :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`@mixin`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$fontsize`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 16`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token property"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$fontbase`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 16`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$fontsize`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$fontsize`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$fontbase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` 1rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Il suffit ainsi d’écrire `}<code parentName="p" {...{
        "className": "text"
      }}>{`@include fs(16)`}</code>{` pour que nos `}<code parentName="p" {...{
        "className": "text"
      }}>{`rem`}</code>{` soient convertis en l’équivalent de `}<code parentName="p" {...{
        "className": "text"
      }}>{`16px`}</code>{`, avec le fallback en `}<code parentName="p" {...{
        "className": "text"
      }}>{`px`}</code>{` pour les vieux navigateurs.`}</p>
    <h2>{`Et le responsive design ?`}</h2>
    <p>{`L’avantage du responsive design, c’est que si l’on utilise `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{` ou `}<code parentName="p" {...{
        "className": "text"
      }}>{`rem`}</code>{` pour nos tailles de police, un changement de taille du `}<code parentName="p" {...{
        "className": "text"
      }}>{`body`}</code>{` grâce aux `}<code parentName="p" {...{
        "className": "text"
      }}>{`@media queries`}</code>{` permet d’impacter l’ensemble de nos éléments.`}</p>
    <p>{`On peut ainsi définir dès le départ une taille pour chacun de nos éléments, et puis changer leur tailles à différents `}<em parentName="p">{`breakpoints`}</em>{`.`}</p>
    <p>{`L’inconvénient de cette méthode, c’est que si on utilise un fallback en `}<code parentName="p" {...{
        "className": "text"
      }}>{`px`}</code>{`, les éléments calculés de cette manière ne seront pas affectés par le changement de `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-size`}</code>{` du `}<code parentName="p" {...{
        "className": "text"
      }}>{`body`}</code>{`, ce qui signifie qu’ils devront être redéfinis ensuite.`}</p>
    <h2>{`D’autres solutions pour l’avenir`}</h2>
    <p>{`il existe d’autres unités de mesure, instituées par CSS3 : `}<code parentName="p" {...{
        "className": "text"
      }}>{`vh`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`vw`}</code>{` `}<code parentName="p" {...{
        "className": "text"
      }}>{`vmin`}</code>{` et `}<code parentName="p" {...{
        "className": "text"
      }}>{`vmax`}</code>{`. Elles sont calculées par rapport à la taille actuelle du viewport. Le support est plutôt correct, mais ceci dit sur très grand écran (ou tout petit) le résultat peut vite s’avérer bizarre…`}</p>
    <h2>{`En conclusion`}</h2>
    <p>{`Il existe plusieurs méthodes de gérer `}<code parentName="p" {...{
        "className": "text"
      }}>{`font-size`}</code>{`; de mon côté j’ai abandonné la méthode des 62.5% et je m’appuie sur le `}<code parentName="p" {...{
        "className": "text"
      }}>{`@mixin`}</code>{` ci-dessus pour configurer mes différentes polices. J’aimerais un peu creuser le retour aux `}<code parentName="p" {...{
        "className": "text"
      }}>{`em`}</code>{` pour plus de simplicité dans la gestion des différentes tailles de police. Un article intitulé `}<a parentName="p" {...{
        "href": "http://csswizardry.com/2011/05/font-sizing-with-rem-could-be-avoided/"
      }}>{`Font sizing with rem could be avoided`}</a>{` a d’ailleurs été écrit sur ce sujet par `}<a parentName="p" {...{
        "href": "http://csswizardry.com/"
      }}>{`Harry Roberts`}</a>{`.`}</p>
    <p className="info">
  Mise à jour (29/08/2015) : je suis finalement revenu à du 62.5% +{' '}
  <code>rem</code> pour la gestion de mes tailles de police; plus simple pour
  les calculs et le support est maintenant{' '}
  <a href="http://caniuse.com/#search=rem">très bon</a>.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      